import { HeliumClient } from '@msi-alta/helium-client';
import { jwtDecode } from 'jwt-decode';
import * as Sentry from '@sentry/react';
// @TODO - refactor this function into here post-refactor
import { parseDebugTeapot } from './request';
import {
  getAccessToken,
  setAccessToken,
  removeAccessToken,
} from './accessToken';
import { getWindowLocation } from './window';

const openpathConfig = require('openpathConfig');

const TOKEN_EXPIRATION_LIMIT = 10800;
const MAINTENANCE_MODE_CHECK_INTERVAL = 1000 * 60 * 5; // 5 minutes

const server = openpathConfig.PORTAL_API_URL;
let token: string | null = null;

if (openpathConfig.ENV !== 'test') {
  token = getAccessToken();
}

export default class Helium {
  static async checkForMaintenanceMode(
    forceCheck = false,
    callback = () => null,
  ) {
    const mCheck = window.sessionStorage.getItem('mCheck');
    if (
      forceCheck ||
      !mCheck ||
      Date.now() - Number(mCheck) > MAINTENANCE_MODE_CHECK_INTERVAL
    ) {
      window.sessionStorage.setItem('mCheck', String(Date.now()));
      try {
        const { json: maintenanceCheck } = await Helium.client
          .raw()
          .checkMaintenanceEndpoint(openpathConfig.configUrl);
        if (maintenanceCheck?.maintenanceMode) {
          const windowLocation = getWindowLocation();
          if (windowLocation.pathname !== '/maintenance') {
            windowLocation.href = '/maintenance';
          }
        } else if (callback) {
          callback();
        }
      } catch (ex) {
        console.error(`Something went wrong with maintenanceCheck (${ex})`);
      }
    }
  }

  static async checkForExpiringToken() {
    if (openpathConfig.ENV === 'test') {
      return;
    }

    const cookieToken = getAccessToken();

    // if we don't have a cookie, just return
    if (!cookieToken) {
      return;
    }

    // If the token is expiring, we need a new one
    let tempToken: { exp: number } | null = null;
    try {
      tempToken = jwtDecode(cookieToken);
    } catch {
      // - Also make this a utility called clearToken() and hook up anywhere we need to do this
      Sentry.captureMessage(
        'Caught error decoding token in checkForExpiringToken(). User will be logged out.',
        {
          contexts: {
            token: { isUndefined: cookieToken === undefined },
          },
        },
      );

      // eslint-disable-next-line no-console
      console.debug(
        'Caught error decoding token in checkForExpiringToken(). User will be logged out.',
      );
      removeAccessToken();
      getWindowLocation().href = '/';
    }
    const expirationTime = tempToken?.exp || 0;
    const currentTime = new Date().getTime() / 1000;

    if (
      expirationTime - currentTime < TOKEN_EXPIRATION_LIMIT &&
      expirationTime - currentTime > 0
    ) {
      removeAccessToken();
      try {
        const response = await Helium.client.refreshLogin();
        const newToken = response.json.data.token;

        setAccessToken(newToken);
      } catch (ex) {
        console.error(`Something went wrong refreshing token ${ex}`);
      }
    }
  }

  // This function will get called on (almost) every request HeliumClient sends
  // out so we can do platinum-specific stuff
  static async postRequestCallback(
    expectCode: any,
    rawResponse: { statusCode: number; json: { message: string } },
  ) {
    if (openpathConfig.ENV === 'test') {
      return;
    }
    const { statusCode } = rawResponse;
    // check status code for specifics
    if (!statusCode) throw new Error('Helium request didnt get a status code!');
    switch (statusCode) {
      // Handle 401 if we used an expired token
      // If so, we are gonna kick them back to login page
      // (this should rarely happen though since we checkForExpiringToken()
      // at the end end of near every request)
      case 401: {
        /** We do not want to run this logic during login for the cases where
         * MFA or namespace are required */
        if (
          ![
            'Multi-factor authentication is required.', // MFA required during regular login
            'Namespace is required.', // When email/password combo associated with multiple namespaces in SSO login and forgot password
          ].includes(rawResponse.json.message)
        ) {
          removeAccessToken();
          getWindowLocation().href = '/';
        }

        break;
      }
      // We co-op the 418 (Teapot) error to distinguish from other errors
      // when we're using `op-scope-check` headers to check API scopes.
      case 418: {
        await parseDebugTeapot(rawResponse);
        break;
      }
      default:
        break;
    }
    // check if we need to be in maintenance mode or if this route is
    // disabled (if interval isn't up it will early out)
    await Helium.checkForMaintenanceMode();

    // Check if our token is about to expire, if so, we'll just refresh it
    // before it runs out
    await Helium.checkForExpiringToken();
  }

  static client = HeliumClient.forLiveServer(
    server || openpathConfig.PORTAL_API_URL,
    token,
    Helium.postRequestCallback,
    true,
  );
}
