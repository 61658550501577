import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { CompatRouter } from 'react-router-dom-v5-compat';
import ConfigProvider from 'antd/es/config-provider';
import { createRoute } from 'utils/createRoute';
import AppContainer from 'routes/AppContainer';
import verifyEmailRoute from 'routes/VerifyEmailPage/route';
import setupAppleWalletPageRoute from 'pages/SetupAppleWalletPage/route';
import setupMobilePageRoute from 'pages/SetupMobilePage/route';
import setupMobileSamlPageRoute from 'routes/SetupMobileSamlPage/route';
import localAdminPageRoute from 'routes/LocalAdminPage/route';
import { RouteProvider } from 'context/RouteContext';
import oAuthCallbackRoute from 'routes/OAuthCallback/route';
import AlertWrapper from 'containers/AlertWrapper';
import { LanguageSelectorNotAuthenticated } from 'new-components/LanguageSelectorNotAuthenticated/LanguageSelectorNotAuthenticated';
import { OpApp } from 'new-components/DLS/OpApp/OpApp';
import { getRawMenuItems } from 'config/utils';
import { useThemeObserver } from 'utils/customHooks/useThemeObserver';
import { selectCurrentIdentityId } from 'routes/AppContainer/selectors';
import { useSelectorJs } from 'utils/customHooks';
import history from './history';
import './importAssets';

export const AccessAppScaffold = () => {
  const identityId = useSelectorJs(selectCurrentIdentityId());
  const { theme } = useThemeObserver({ identityId });
  const menuItems = useMemo(() => getRawMenuItems(), []);

  return (
    <ConfigProvider theme={theme}>
      {/** Need to wrap in antd App so modal confirm works with dark mode theme
       * https://github.com/ant-design/ant-design/issues/40576
       */}

      <RouteProvider routes={menuItems}>
        <OpApp className="op-app">
          <ConnectedRouter history={history}>
            <CompatRouter>
              <Switch>
                {/* These routes don't need app container to mount so they can be loaded here to be lighter weight */}
                {createRoute(setupMobilePageRoute, true)}
                {createRoute(setupAppleWalletPageRoute, true)}
                {createRoute(setupMobileSamlPageRoute, true)}
                {createRoute(localAdminPageRoute, true)}
                {createRoute(oAuthCallbackRoute, true)}

                {/* this may not be used any more */}
                {createRoute(verifyEmailRoute, true)}
                <Route path="/" component={AppContainer} />
              </Switch>

              <AlertWrapper />
            </CompatRouter>
          </ConnectedRouter>
          <LanguageSelectorNotAuthenticated />
        </OpApp>
      </RouteProvider>
    </ConfigProvider>
  );
};
