import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n/i18n';
import { QueryClientProvider } from '@tanstack/react-query';
import { TanstackDevtools } from 'new-components/TanstackDevtools/TanstackDevtools';
import { useAvigilonAltaDomainRedirect } from 'utils/customHooks/useAvigilonAltaDomainRedirect';
import { useCreateQueryClient } from 'utils/customHooks/useCreateQueryClient';
import { AppProvider } from 'new-components/OpAppScaffold/AppContext';
import { AccessAppScaffold } from './AccessAppScaffold';
import './importAssets';

export default () => {
  useAvigilonAltaDomainRedirect();

  // Create a react-query client
  const queryClient = useCreateQueryClient();

  return (
    <I18nextProvider lng="en" defaultNS="translation" i18n={i18n}>
      <AppProvider app="access">
        <QueryClientProvider client={queryClient}>
          <AccessAppScaffold />
          <TanstackDevtools />
        </QueryClientProvider>
      </AppProvider>
    </I18nextProvider>
  );
};
