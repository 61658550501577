import { useEffect } from 'react';
import { getWindowLocation } from 'utils/window';

const OLD_HOSTNAME = 'control.openpath.com';
const OLD_HOSTNAME_EU = 'control.eu.openpath.com';

const NEW_HOSTNAME = 'control.alta.avigilon.com';
const NEW_HOSTNAME_EU = 'control.eu.alta.avigilon.com';

export const useAvigilonAltaDomainRedirect = () => {
  useEffect(() => {
    const windowLocation = getWindowLocation();

    const url = new URL(windowLocation.href);

    /**
     * Redirect is not necessary because hostname does not include:
     * - control.openpath.com
     * - control.eu.openpath.com
     */
    if (![OLD_HOSTNAME, OLD_HOSTNAME_EU].includes(url.hostname)) {
      return;
    }

    if (url.hostname === OLD_HOSTNAME_EU) {
      url.hostname = NEW_HOSTNAME_EU;
    } else {
      url.hostname = NEW_HOSTNAME;
    }

    windowLocation.assign(url.toString());
  }, []);
};
