import {
  OpSelect,
  OpSelectSingleProps,
} from 'new-components/DLS/OpSelect/OpSelect';
import { sortOptionsAlphabetically } from 'utils/sortOptionsAlphabetically';
import { useSupportedLanguages } from '../../utils/customHooks/useSupportedLanguages';

type LanguageSelectorProps = Pick<
  OpSelectSingleProps<string, { value: string; label: string }>,
  'value' | 'onChange'
>;

export const LanguageSelector = ({
  value,
  onChange,
}: LanguageSelectorProps) => {
  const supportedLanguages = useSupportedLanguages();

  const languageOptions = supportedLanguages.map(({ code, nativeName }) => ({
    value: code,
    label: nativeName,
  }));

  const selectedLanguage = languageOptions.find(
    ({ label: language }) => language === value,
  );

  return (
    <OpSelect
      value={
        selectedLanguage?.value || // if we have a match, use that
        (typeof value === 'string' && value.split('-')[0]) || // i18n.language is en-US, we want en
        'en' // default to english
      }
      onChange={onChange}
      options={languageOptions}
      filterSort={sortOptionsAlphabetically}
    />
  );
};
